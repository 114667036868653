"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = __importStar(require("underscore"));
const Utility = __importStar(require("./Utility"));
class Player {
    constructor(userID, botType) {
        this.userID_ = userID;
        this.botType_ = botType || null;
        this.active = [];
        this.reserve = [];
        this.spent = [];
        this.discard = [];
        this.queuedDiscardIDs = [];
        this.selectedCardIDs = [];
        this.counters = Utility.makeCounterDelta();
    }
    getID() {
        return this.userID_;
    }
    getBotType() {
        return this.botType_;
    }
    render() {
        return {
            userID: this.userID_,
            botType: this.botType_,
            activeIDs: _.map(this.active, (card) => card.id),
            reserveIDs: _.map(this.reserve, (card) => card.id),
            spentIDs: _.map(this.spent, (card) => card.id),
            discardIDs: _.map(this.discard, (card) => card.id),
            queuedDiscardIDs: this.queuedDiscardIDs,
            selectedCardIDs: this.selectedCardIDs,
            counters: this.counters,
        };
    }
    toJSON() {
        return {
            userID: this.userID_,
            botType: this.botType_,
            activeIDs: _.map(this.active, (card) => card.id),
            reserveIDs: _.map(this.reserve, (card) => card.id),
            spentIDs: _.map(this.spent, (card) => card.id),
            discardIDs: _.map(this.discard, (card) => card.id),
            queuedDiscardIDs: this.queuedDiscardIDs,
            selectedCardIDs: this.selectedCardIDs,
            counters: this.counters,
        };
    }
    static fromJSON(json, cardsByID) {
        let ret = new Player(json.userID, json.botType);
        ret.userID_ = json.userID;
        ret.botType_ = json.botType;
        ret.active = json.activeIDs.map((id) => cardsByID[id]);
        ret.reserve = json.reserveIDs.map((id) => cardsByID[id]);
        ret.spent = json.spentIDs.map((id) => cardsByID[id]);
        ret.discard = json.discardIDs.map((id) => cardsByID[id]);
        ret.queuedDiscardIDs = json.queuedDiscardIDs;
        ret.selectedCardIDs = json.selectedCardIDs;
        ret.counters = json.counters;
        return ret;
    }
}
exports.default = Player;
