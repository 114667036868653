"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCardTypeString = exports.nonBasicTypes = exports.CardTypeSet = void 0;
const cardTypeStrings = {
    BASIC: 'Basic',
    RESOURCE: 'Resource',
    CONFLICT: 'Conflict',
    PRAYER: 'Prayer',
    EPIC: 'Epic',
};
exports.CardTypeSet = {
    BASIC: 'BASIC',
    RESOURCE: 'RESOURCE',
    CONFLICT: 'CONFLICT',
    PRAYER: 'PRAYER',
    EPIC: 'EPIC',
};
exports.nonBasicTypes = [
    exports.CardTypeSet.RESOURCE,
    exports.CardTypeSet.CONFLICT,
    exports.CardTypeSet.PRAYER,
];
function getCardTypeString(cardType) {
    return cardTypeStrings[cardType];
}
exports.getCardTypeString = getCardTypeString;
exports.default = exports.CardTypeSet;
