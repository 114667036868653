"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGraphQLQuery = exports.usersQuery = exports.lobbyQuery = exports.lobbyListQuery = void 0;
const _ = __importStar(require("underscore"));
const user_summary_fragment = `
fragment UserSummaryFragment on User {
  id
  name
  profilePictureURL
  stats {
    totalWins
    totalGames
    totalBlameTime
  }
}`;
exports.lobbyListQuery = `
{
  lobby_list {
    id
    name
    host {
      ...UserSummaryFragment
    }
    creationDate
    status
    game {
      id
    }
    players {
      edges {
        node {
          ...UserSummaryFragment
        }
      }
    }
  }
}
${user_summary_fragment}
`;
exports.lobbyQuery = `
query getLobby($id: String!) {
  lobby (id: $id) {
    id
    sequenceID
    name
    cardset
    options
    host {
      ...UserSummaryFragment
    }
    players {
      edges {
        node {
          ...UserSummaryFragment
        }
      }
    }
    game {
      id
    }
  }
}
${user_summary_fragment}
`;
function usersQuery(user_ids) {
    let pieces = _.map(user_ids, (user_id, i) => {
        return `"${user_id}"`;
    });
    return `
{
  users(ids: [${pieces.join(',')}]) {
    ...UserSummaryFragment
  }
}
${user_summary_fragment}
`;
}
exports.usersQuery = usersQuery;
function makeGraphQLQuery(query, params) {
    let param_string = '';
    if (params) {
        let encoded_params = JSON.stringify(params);
        param_string = `&variables=${encoded_params}`;
    }
    return fetch(`/api/graphql?query=${query}${param_string}`, {
        headers: {
            Accept: 'application/json',
        },
        credentials: 'same-origin',
    })
        .then((response) => response.json())
        .then((result) => {
        if (result.data) {
            return result.data;
        }
        console.warn('error running graphql query', result.errors.map((err) => err.message), result.errors, { query });
        return Promise.reject(result.errors);
    });
}
exports.makeGraphQLQuery = makeGraphQLQuery;
