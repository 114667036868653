"use strict";
/*
 * Use scripts/print_card_ids.js to print out the IDs to use here.
 * cardDefIDsByAge: fill this with an array of 3 arrays.  Each
 * sub array corresponds to the order of cards in the deck, with the bottom
 * of the deck listed first.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    cardDefIDsByAge: [
        [
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
        ],
        [
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
        ],
        [
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
        ],
    ],
    /*
     * For each turn you'd like to set the rolls for, you need to supply an array of the rolls
     * for each round.  Each round should have a set of rolls for each player.
     *
     * Ties are handled by adding multiple rounds for a given turn
     */
    rollsByRoundByTurn: [
        [[[1], [1], [1]]],
        [
            [[1], [1], [1]],
            [[1], [2], [3]],
        ],
        [
            [[1], [1], [1]],
            [[1], [2], [3]],
        ],
        [
            [[1], [1], [1]],
            [[1], [2], [3]],
        ],
        [[[1], [1], [1]]],
        [[[1], [1], [1]]],
    ],
    botActionByTurn: [
        {
            dummy0: {
                tableIndex: 0,
                military: 'none',
                gold: 'discard',
            },
            dummy1: {
                tableIndex: 1,
                military: 'active',
                gold: 'discard',
            },
        },
        {
            dummy0: {
                tableIndex: 0,
                military: 'none',
                gold: 'discard',
            },
            dummy1: {
                tableIndex: 1,
                military: 'active',
                gold: 'discard',
            },
        },
        {
            dummy0: {
                tableIndex: 0,
                military: 'discard',
                gold: 'discard',
            },
            dummy1: {
                tableIndex: 0,
                military: 'discard',
                gold: 'discard',
            },
        },
        {
            dummy0: {
                tableIndex: 0,
                military: 'none',
                gold: 'discard',
            },
            dummy1: {
                tableIndex: 1,
                military: 'active',
                gold: 'discard',
            },
        },
        {
            dummy0: {
                tableIndex: 0,
                military: 'none',
                gold: 'discard',
            },
            dummy1: {
                tableIndex: 1,
                military: 'active',
                gold: 'discard',
            },
        },
    ],
};
