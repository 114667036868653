"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nextPhase = exports.getPhaseOrder = exports.PhaseOrder = exports.getPhaseName = exports.Phases = void 0;
exports.Phases = {
    PLANNING: 'PLANNING',
    RESOLUTION: 'RESOLUTION',
};
function getPhaseName(phase) {
    switch (phase) {
        case exports.Phases.PLANNING:
            return 'Planning';
        case exports.Phases.RESOLUTION:
            return 'Resolution';
        default:
            console.log('cannot find phase: ' + phase);
            return '';
    }
}
exports.getPhaseName = getPhaseName;
exports.PhaseOrder = [exports.Phases.PLANNING, exports.Phases.RESOLUTION];
function getPhaseOrder() {
    return exports.PhaseOrder;
}
exports.getPhaseOrder = getPhaseOrder;
function nextPhase(phase) {
    var index = exports.PhaseOrder.indexOf(phase);
    var new_index = (index + 1) % exports.PhaseOrder.length;
    return exports.PhaseOrder[new_index];
}
exports.nextPhase = nextPhase;
exports.default = exports.Phases;
