"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultGameOptions = exports.getGameOptionData = void 0;
function getGameOptionData() {
    return {
        showDeckInfo: {
            name: 'Show Deck',
            key: 'showdeck',
            tooltip: 'Reveal the top card of each deck and list the number of cards remaining.',
        },
        tradeRowSlide: {
            name: 'Slide Trade Row',
            key: 'slide',
            tooltip: 'Remove the leftmost card on the trade row at the end of each turn if it is not drafted.',
        },
        prerollDice: {
            name: 'Preroll Dice',
            key: 'preroll',
            tooltip: 'Shows your dice rolls to you during the planning phase.',
        },
        enableToggleButton: {
            name: 'Active Toggle Button',
            key: 'toggle',
            tooltip: 'Adds option to toggle all cards active.',
        },
        tutorial: {
            name: 'Tutorial',
            key: 'tutorial',
            tooltip: 'Runs a tutorial.',
        },
    };
}
exports.getGameOptionData = getGameOptionData;
function getDefaultGameOptions() {
    return {
        showDeckInfo: false,
        tradeRowSlide: false,
        prerollDice: false,
        enableToggleButton: false,
        tutorial: false,
    };
}
exports.getDefaultGameOptions = getDefaultGameOptions;
