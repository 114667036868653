"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
require("isomorphic-fetch");
const jwt = __importStar(require("jsonwebtoken"));
class Session {
    constructor(sessionUser) {
        this.jwtPromise_ = null;
        this.sessionUser_ = sessionUser;
    }
    getUser() {
        return this.sessionUser_;
    }
    getUserID() {
        return this.sessionUser_.id;
    }
    toJSON() {
        return {
            user: this.sessionUser_,
        };
    }
    genJWT() {
        if (!this.jwtPromise_) {
            this.jwtPromise_ = this.genFetchJWT_();
        }
        return this.jwtPromise_;
    }
    genFetchJWT_() {
        if (window && window.localStorage) {
            const cachedJWT = window.localStorage.getItem('jwt');
            if (this.isValidJWT_(cachedJWT)) {
                return Promise.resolve(cachedJWT);
            }
        }
        return fetch('/auth/jwt', { credentials: 'same-origin' })
            .then((response) => {
            return response.text();
        })
            .then((jwt) => {
            if (window && window.localStorage) {
                window.localStorage.setItem('jwt', jwt);
            }
            return jwt;
        });
    }
    isValidJWT_(token) {
        if (!token) {
            return false;
        }
        const decoded = jwt.decode(token);
        if (!decoded) {
            return false;
        }
        return decoded.sub === this.getUserID();
    }
    static fetchServerSession(req, cb) {
        if (!req.isAuthenticated()) {
            return cb(null, null);
        }
        var user = req.user.toJSON();
        var session = new Session(user);
        return cb(null, session);
    }
    static fromJSON(json) {
        return new Session(json.user);
    }
}
exports.default = Session;
