"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Piles = {
    DRAFT_ROW: 'DRAFT_ROW',
    BASIC_CARDS: 'BASIC_CARDS',
    TRIBUTE_CARDS: 'TRIBUTE_CARDS',
    RESERVE: 'RESERVE',
    ACTIVE: 'ACTIVE',
    SPENT: 'SPENT',
};
exports.default = Piles;
