"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTributeLogic = void 0;
const _ = __importStar(require("underscore"));
const sprintf_js_1 = require("sprintf-js");
const CardTypes_1 = __importStar(require("./CardTypes"));
const SelectionTypes_1 = require("./SelectionTypes");
const Utility = __importStar(require("./Utility"));
var TributeMixin = {
    favor: function () {
        // could be cleaned up?
        return this.favorvals;
    },
    thresholds() {
        return (this.params && this.params.value) || null;
    },
};
function createTributeLogic(name, favor1, favor2, favor3, params) {
    if (!name) {
        return null;
    }
    var def = TributeDefinitions[name];
    if (!def) {
        return null;
    }
    return Object.assign(Object.assign({ params, favorvals: [favor1, favor2, favor3] }, TributeMixin), def);
}
function getTributeLogic(card) {
    return createTributeLogic(card.tribute_class, card.favor1, card.favor2, card.favor3, card.params);
}
exports.getTributeLogic = getTributeLogic;
var get_optimistic_board = function (context) {
    var player = context.player;
    var board = Utility.getPlayerBoard(player);
    var gain_sel = _.find(context.selections, function (selection) {
        return selection.type === SelectionTypes_1.SelectionTypes.GAIN;
    });
    if (gain_sel) {
        board.push(gain_sel.card);
    }
    return board;
};
var get_best_non_epic_score = function (table, scoring_function) {
    var non_epic_table_cards = _.filter(table, (card) => {
        return card.type !== CardTypes_1.default.EPIC;
    });
    var best_score = _.max(_.map(non_epic_table_cards, scoring_function));
    return best_score;
};
const count_cards_of_type = function (context, type) {
    let board = get_optimistic_board(context);
    return Utility.countIf(board, (card) => {
        return card.type === type;
    });
};
function count_basic_cards(context) {
    let board = get_optimistic_board(context);
    return Utility.countIf(board, (card) => {
        return card.isBasic;
    });
}
var TributeDefinitions = {
    OwnCardsOfType: {
        description: function () {
            return sprintf_js_1.sprintf('Own X %s cards.', CardTypes_1.getCardTypeString(this.params.type));
        },
        hasEnough_: function (context) {
            let count = count_cards_of_type(context, this.params.type);
            let threshold = this.params.value[context.age - 1];
            return count >= threshold;
        },
        planningDataFunction: function (context, results, players) {
            return this.hasEnough_(context);
        },
        resolutionDataFunction: function (context, table) {
            return this.hasEnough_(context);
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return !!planning_data || !!resolution_data;
        },
    },
    OwnBasicCards: {
        description: function () {
            return sprintf_js_1.sprintf('Own X Basic cards.');
        },
        hasEnough_: function (context) {
            let count = count_basic_cards(context);
            let threshold = this.params.value[context.age - 1];
            return count >= threshold;
        },
        planningDataFunction: function (context, results, players) {
            return this.hasEnough_(context);
        },
        resolutionDataFunction: function (context, table) {
            return this.hasEnough_(context);
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return !!planning_data || !!resolution_data;
        },
    },
    OwnCardsOfAllTypes: {
        description: function () {
            return 'Own X sets of a Resource, Conflict, and Prayer card.';
        },
        hasEnough_: function (context) {
            let board = get_optimistic_board(context);
            let threshold = this.params.value[context.age - 1];
            return Utility.countBoardSets(board) >= threshold;
        },
        planningDataFunction: function (context, results, players) {
            return this.hasEnough_(context);
        },
        resolutionDataFunction: function (context, table) {
            return this.hasEnough_(context);
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return !!planning_data || !!resolution_data;
        },
    },
    ObtainCardOfType: {
        description: function () {
            return sprintf_js_1.sprintf('Obtain a %s card from the Trade Row this turn.', CardTypes_1.getCardTypeString(this.params.type));
        },
        resolutionDataFunction: function (context, table) {
            var gain_sel = _.find(context.selections, function (selection) {
                return selection.type === SelectionTypes_1.SelectionTypes.GAIN;
            });
            return (gain_sel &&
                !gain_sel.card.isBasic &&
                gain_sel.card.type === this.params.type);
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return !!resolution_data;
        },
    },
    DefeatOpponentByAmount: {
        description: function () {
            return sprintf_js_1.sprintf('Defeat an opponent by X in a conflict.');
        },
        planningDataFunction: function (context, results, players) {
            // @ts-ignore
            var player_id = context.player.userID_;
            if (player_id !== results.winningPlayerID) {
                return 0;
            }
            var biggest_difference = 0;
            _.each(results.rounds, (round) => {
                var player_military = round[player_id].total;
                var military_differences = [];
                _.each(round, (other_result, other_player_id) => {
                    if (other_player_id === player_id) {
                        return;
                    }
                    var other_player_military = other_result.total;
                    military_differences.push(player_military - other_player_military);
                });
                biggest_difference = Math.max.apply(null, military_differences.concat(biggest_difference));
            });
            return biggest_difference;
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return (planning_data || 0) >= this.params.value[context.age - 1];
        },
    },
    ActivateCards: {
        description: function () {
            return sprintf_js_1.sprintf('Prepare to spend X cards this turn.');
        },
        planningDataFunction: function (context, results, players) {
            return _.size(_.unique(context.queuedActiveCardIDs));
        },
        resolutionDataFunction: function (context, table) {
            return _.size(_.unique(context.queuedActiveCardIDs));
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return ((planning_data || 0) + (resolution_data || 0) >=
                this.params.value[context.age - 1]);
        },
    },
    DiscardCards: {
        description: function () {
            return sprintf_js_1.sprintf('Prepare to discard X cards this turn.');
        },
        planningDataFunction: function (context, results, players) {
            return _.size(_.unique(context.queuedDiscardIDs));
        },
        resolutionDataFunction: function (context, table) {
            return _.size(_.unique(context.queuedDiscardIDs));
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return ((planning_data || 0) + (resolution_data || 0) >=
                this.params.value[context.age - 1]);
        },
    },
    HaveFavorUnderWinningPlayer: {
        description: function () {
            return sprintf_js_1.sprintf('Have %s less than the winning player at the beginning of the turn.', Utility.makeXCounterString('favor'));
        },
        planningDataFunction: function (context, results, players) {
            const highest_favor_player = _.max(players, function (player) {
                return player.counters.favor;
            });
            if (!highest_favor_player) {
                return false;
            }
            const highest_favor = highest_favor_player.counters.favor;
            return (context.player.counters.favor <=
                highest_favor - this.params.value[context.age - 1]);
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return !!planning_data;
        },
    },
    GainFavorFromOtherSources: {
        description: function () {
            return sprintf_js_1.sprintf('Gain %s from other sources this turn.', Utility.makeXCounterString('favor'));
        },
        resolutionDataFunction: function (context, table) {
            return context.counter_delta.favor;
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return ((resolution_data || 0) + context.tribute_favor >=
                this.params.value[context.age - 1]);
        },
    },
    OwnCardsCostingAmount: {
        description: function () {
            return sprintf_js_1.sprintf('Own %s cards that each cost at least %s.', this.params.count, Utility.makeXCounterString('gold'));
        },
        hasEnough_: function (context) {
            let board = get_optimistic_board(context);
            let count = Utility.countIf(board, (card) => {
                return card.cost >= this.params.value[context.age - 1];
            });
            return count >= this.params.count;
        },
        planningDataFunction: function (context, results, players) {
            return this.hasEnough_(context);
        },
        resolutionDataFunction: function (context, table) {
            return this.hasEnough_(context);
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return !!planning_data || !!resolution_data;
        },
    },
    HaveDiscardedCards: {
        description() {
            return sprintf_js_1.sprintf('Have X cards in your discard pile.');
        },
        hasEnough_(context) {
            let discarded_ids = _.union(_.pluck(context.player.discard, 'id'), context.player.queuedDiscardIDs, context.queuedDiscardIDs);
            return discarded_ids.length >= this.params.value[context.age - 1];
        },
        planningDataFunction(context, results, players) {
            return this.hasEnough_(context);
        },
        resolutionDataFunction(context, table) {
            return this.hasEnough_(context);
        },
        shouldGainCard(context, planning_data, resolution_data) {
            return !!planning_data || !!resolution_data;
        },
    },
    ProduceCounters: {
        description() {
            return sprintf_js_1.sprintf('Produce %s using cards you own.', Utility.makeXCounterString(this.params.type));
        },
        planningDataFunction(context, results, players) {
            return context.production_delta[this.params.type];
        },
        resolutionDataFunction(context, table) {
            return context.production_delta[this.params.type];
        },
        shouldGainCard(context, planning_data, resolution_data) {
            var sum = 0;
            sum += planning_data || 0;
            sum += resolution_data || 0;
            return sum >= this.params.value[context.age - 1];
        },
    },
    DefeatWinningOpponent: {
        description() {
            return sprintf_js_1.sprintf('Defeat an opponent in war who has the highest favor amongst all players.');
        },
        planningDataFunction: function (context, results, players) {
            // @ts-ignore
            var player_id = context.player.userID_;
            if (player_id !== results.winningPlayerID) {
                return false;
            }
            var winning_player_ids = [];
            var highest_favor = 0;
            _.each(players, function (player) {
                if (player.counters.favor >= highest_favor) {
                    if (player.counters.favor > highest_favor) {
                        // @ts-ignore
                        winning_player_ids = [player.userID_];
                    }
                    else {
                        // @ts-ignore
                        winning_player_ids.push(player.userID_);
                    }
                    highest_favor = player.counters.favor;
                }
            });
            return _.any(results.rounds, function (round) {
                var player_total = _.find(round, function (player_results) {
                    return player_results.playerID === player_id;
                }).total;
                return _.any(round, function (player_results) {
                    var opponent_id = player_results.playerID;
                    if (opponent_id === player_id) {
                        return false;
                    }
                    return (player_results.total < player_total &&
                        _.contains(winning_player_ids, opponent_id));
                });
            });
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return !!planning_data;
        },
    },
    ObtainMostExpensiveCard: {
        description: function () {
            return sprintf_js_1.sprintf('Obtain a non-Epic card that costs the most among non-Epic cards on the Trade Row.');
        },
        resolutionDataFunction: function (context, table) {
            var highest_total_cost = get_best_non_epic_score(table, (card) => card.cost);
            var gain_sel = _.find(context.selections, function (selection) {
                return (selection.type === SelectionTypes_1.SelectionTypes.GAIN &&
                    selection.card.type !== CardTypes_1.default.EPIC &&
                    !selection.card.isBasic);
            });
            return gain_sel && gain_sel.card.cost >= highest_total_cost;
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return !!resolution_data;
        },
    },
    ObtainCardWithHighestFavorValue: {
        description: function () {
            return sprintf_js_1.sprintf('Obtain a non-Epic card that grants the most base favor among non-Epic cards on the Trade Row.');
        },
        resolutionDataFunction: function (context, table) {
            var highest_favor_value = get_best_non_epic_score(table, (card) => card.favor);
            var gain_sel = _.find(context.selections, (selection) => {
                return (selection.type === SelectionTypes_1.SelectionTypes.GAIN &&
                    selection.card.type !== CardTypes_1.default.EPIC &&
                    !selection.card.isBasic);
            });
            return gain_sel && gain_sel.card.favor >= highest_favor_value;
        },
        shouldGainCard: function (context, planning_data, resolution_data) {
            return !!resolution_data;
        },
    },
};
