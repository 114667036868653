"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDuration = void 0;
function formatDuration(milliseconds) {
    var seconds = milliseconds / 1000;
    var hours = Math.floor(seconds / (60 * 60));
    var minutes = Math.floor(seconds / 60) % 60;
    seconds = Math.floor(seconds) % 60;
    var ret = '';
    if (hours) {
        ret += hours + ':';
    }
    if (minutes < 10) {
        ret += '0';
    }
    ret += minutes + ':';
    if (seconds < 10) {
        ret += '0';
    }
    ret += seconds;
    return ret;
}
exports.formatDuration = formatDuration;
