"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceNameSet = exports.BasicResources = exports.CharacterToResourceName = exports.NameToResourceCharacter = void 0;
exports.NameToResourceCharacter = {
    gold: 'G',
    favor: 'V',
    military: 'M',
};
exports.CharacterToResourceName = {
    G: 'gold',
    V: 'favor',
    M: 'military',
};
exports.BasicResources = ['gold'];
exports.ResourceNameSet = {
    gold: 'gold',
    favor: 'favor',
    military: 'military',
};
