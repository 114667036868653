"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventTypes;
(function (EventTypes) {
    EventTypes["SET_UP_AGE"] = "SET_UP_AGE";
    EventTypes["SET_UP_TURN"] = "SET_UP_TURN";
    EventTypes["SET_UP_PHASE"] = "SET_UP_PHASE";
    EventTypes["END_OF_PHASE"] = "END_OF_PHASE";
    EventTypes["CONFLICT_RESULTS"] = "CONFLICT_RESULTS";
    EventTypes["GAIN_CARD"] = "GAIN_CARD";
    EventTypes["GAIN_TRIBUTES"] = "GAIN_TRIBUTES";
    EventTypes["DISCARD_CARDS"] = "DISCARD_CARDS";
    EventTypes["REFUND_CARDS"] = "REFUND_CARDS";
    EventTypes["TRIBUTES_RESHUFFLED"] = "TRIBUTES_RESHUFFLED";
    EventTypes["DEAL_CARD"] = "DEAL_CARD";
    EventTypes["DEAL_TRIBUTE"] = "DEAL_TRIBUTE";
    EventTypes["SLIDE_CARD"] = "SLIDE_CARD";
    EventTypes["CHAT"] = "CHAT";
})(EventTypes || (EventTypes = {}));
exports.default = EventTypes;
